import { useEffect, useState } from "react";
import { useLazyGetClassSessionQuery } from "shared/redux/endpoints/calendar";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectAuth } from "shared/redux/slices/authSlice";
export const useWeeklyCalendar = (convertDataWorkShop) => {
  const auth = useSelector(selectAuth);

  const { role } = auth;

  const tabs = [
    { label: "All", value: "all" },
    { label: "Lesson", value: "session" },
    { label: "Demo/PT", value: "pt" },
    { label: "Tasks", value: "task" },
    ...(role !== "CSO"
      ? [
          { label: "EOCC & Test", value: "test" },
          { label: "Training", value: "training" },
        ]
      : []),
    { label: "Workshop", value: "workshop" },
  ];

  const [tab, setTab] = useState(tabs[0]);
  const [events, setEvents] = useState([]);
  const [eventsByDay, setEventsByDay] = useState({});
  const [start, setStart] = useState(moment().format("DD-MM-YYYY"));
  const [end, setEnd] = useState(moment().format("DD-MM-YYYY"));
  const [timezone, setTimeZone] = useState((new Date().getTimezoneOffset() / 60) * -1);

  const [trigger, result] = useLazyGetClassSessionQuery();

  const convertTime = (time, diff = 0) =>
    moment(time)
      .add(-7, "hours")
      .add(diff, "minutes")
      .add(timezone, "hours")
      .format("YYYY-MM-DD HH:mm:ss");

  const mapEventProperties = (item, key) => {
    const typeMapping = {
      classSessionScheduleList: "Lesson",
      testEOCCList: "EOCC & Test",
      ptDemoList: "PT/Demo",
      feedback: "Feedback",
      dailyReports: "Daily Report",
      gradeBookReports: "Gradebook Report",
      progressReports: "Progress Report",
      studentAtRiskTask: "Students at Risk",
      workShop: "Work Shop",
    };

    const colorMapping = {
      overdue: "red",
      before: {
        Lesson: "#BCD9F2",
        "EOCC & Test": "#F4CE14",
        "PT/Demo": "#EABEFF",
        "Work Shop": "#FFAEA5",
        default: "springgreen",
      },
      during: {
        Lesson: "#2576B9",
        Feedback: "#0DCAF0",
        "Work Shop": "#FFAEA5",
        default: "#FFEBDA",
      },
      after: "grey",
    };

    const determineColor = () => {
      const { startTime, endTime, deadlineDate, deadline, gradeBookDue } = item;
      const now = moment();

      const start = moment(startTime || deadline || gradeBookDue || deadlineDate);
      const end = moment(endTime);

      if (now.isAfter(end)) return colorMapping.after;
      if (now.isSameOrBefore(start, "day"))
        return colorMapping.before[typeMapping[key]] || colorMapping.before.default;

      return colorMapping.during[typeMapping[key]] || colorMapping.during.default;
    };

    const determineTimeRange = () => {
      const time = {
        start: convertTime(item.startTime || item.deadline || item.gradeBookDue),
        end: convertTime(item.endTime || item.deadline || item.gradeBookDue, 30),
      };
      return time;
    };

    return {
      type: typeMapping[key] || "",
      color: determineColor(),
      timeRange: determineTimeRange(),
      title: item.className || item.eventTypeName || item.feedBackCode || "",
      deadline: convertTime(item.deadline || item.gradeBookDue || item.deadlineDate),
    };
  };

  const groupEventsByDay = (events) =>
    events.reduce((acc, event) => {
      const date = event.start.split(" ")[0];
      acc[date] = acc[date] || [];
      acc[date].push(event);
      return acc;
    }, {});

  useEffect(() => {
    if (start && end) {
      trigger({ page: "", size: 100, sort: "", start, end, type: tab.value });
    }
  }, [start, end, tab]);

  console.log(tab.value);

  useEffect(() => {
    if (result?.data?.status) {
      const isRenderWorkshop = tab.value === "workshop" || tab.value === "all";
      const rawData = {
        ...result?.data?.data,
        workShop: isRenderWorkshop ? convertDataWorkShop : [] || {},
      };

      const newEvents = Object?.entries(rawData)?.flatMap(([key, items]) =>
        Array.isArray(items)
          ? items.map((item) => {
              const { type, color, timeRange, title, deadline } = mapEventProperties(item, key);
              return {
                ...timeRange,
                title,
                backgroundColor: color,
                extendedProps: { data: item, deadline },
                type,
              };
            })
          : []
      );

      const filteredEvents = newEvents.filter((event) =>
        tab.value === "all"
          ? event.type !== "Daily Report"
          : tab.value === "task"
          ? event.type !== "Lesson"
          : true
      );

      setEvents(filteredEvents);
      setEventsByDay(groupEventsByDay(filteredEvents));
    } else {
      setEvents([]);
      setEventsByDay({});
    }
  }, [result, tab, timezone, convertDataWorkShop]);

  return {
    events,
    eventsByDay,
    setEnd,
    setStart,
    handleChangeDate: (start, end) => {
      setStart(start);
      setEnd(end);
    },
    tab,
    tabs,
    setTab,
    timezone,
    setTimeZone,
    loading: result.isFetching,
  };
};
