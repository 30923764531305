import { api } from "shared/redux/api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPhaseBoard: build.query({
      query: () => {
        return {
          url: `/private/development/v1/progress`,
        };
      },
    }),

    getListPhase: build.query({
      query: () => {
        return {
          url: `/private/development/v1/course`,
        };
      },
    }),

    getDataWorkshop: build.query({
      query: (email: string) => {
        return {
          // url: "https://dl.apollo.vn/api/v1/workshops/?format=json&username=demo",
          url: `https://dl.apollo.vn/api/v1/workshops/?format=json&username=${email}`,
          // url: "https://dl.apollo.vn/api/v1/workshops/?format=json&username=cristina.palomares@apollo.edu.vn",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPhaseBoardQuery,
  useGetListPhaseQuery,
  useLazyGetDataWorkshopQuery,
  useLazyGetPhaseBoardQuery,
  useLazyGetListPhaseQuery,
} = injectedRtkApi;
