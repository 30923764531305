export const MyDevelopmentTabEnum = {
  Course: "Course",
  Workshop: "Workshop",
  Observation: "Observation",
};

export const MyDevelopmentData = {
  tabs: [
    { index: 0, title: MyDevelopmentTabEnum.Course, link: "my-tasks" },
    { index: 1, title: MyDevelopmentTabEnum.Workshop, link: "my-classes" },
    { index: 2, title: MyDevelopmentTabEnum.Observation, link: "my-students" },
  ],
  workshopTabs: [{ title: "Available" }, { title: "Registered" }, { title: "Attended" }],
};

export const WorkshopTabEnum = {
  Available: "Available",
  Registered: "Registered",
  Attended: "Attended",
};

export const WorkshopTabsData = {
  tabs: [
    { index: 0, title: WorkshopTabEnum.Available, link: "my-tasks" },
    { index: 1, title: WorkshopTabEnum.Registered, link: "my-classes" },
    { index: 2, title: WorkshopTabEnum.Attended, link: "my-students" },
  ],
};
