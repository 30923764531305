import React from "react";
import MDBox from "../MDBox";
import GroupTabButton, { GroupTabButtonProps } from "../GroupTabButton/GroupTabButton";
import Grid from "@mui/material/Grid";
import { WorkshopTabEnum, WorkshopTabsData } from "data/my-development/MyDevelopment.data";
import WorkshopAvailableTable, {
  WorkshopAvailableTableProps,
} from "../WorkshopAvailableTable/WorkshopAvailableTable";
import { WorkshopAvailableTableInterface } from "../WorkshopAvailableTable/WorkshopAvailableTable.interface";
import WorkshopRegisteredTable from "../WorkshopRegisteredTable/WorkshopRegisteredTable";
import { WorkshopRegisteredTableInterface } from "../WorkshopRegisteredTable/WorkshopRegisteredTable.interface";
import WorkshopAttendedTable from "../WorkshopAttendedTable/WorkshopAttendedTable";
import type { WorkshopAttendedTableInterface } from "../WorkshopAttendedTable/WorkshopAttendedTable.interface";
import type { WorkshopAttendedTableProps } from "../WorkshopAttendedTable/WorkshopAttendedTable";
import { Loading } from "shared/components/ui/Loading";

export interface TabWorkshopProps {
  tabWorkshop: WorkshopTabEnum;
  dataTableWorkshopAvailable: WorkshopAvailableTableInterface[];
  dataTableWorkshopRegistered: WorkshopRegisteredTableInterface[];
  dataTableWorkshopAttended: WorkshopAttendedTableInterface[];
  onClickTab: (tab: WorkshopTabEnum) => void;
  onCheckSchedule: (courseId: number, organizerId: number) => void;
}

const TabWorkshop = ({ ...rest }: TabWorkshopProps) => {
  const {
    tabWorkshop,
    dataTableWorkshopAvailable,
    dataTableWorkshopRegistered,
    dataTableWorkshopAttended,
    onClickTab,
    onCheckSchedule,
    isLoadingData,
  } = rest;

  const propertiesWorkshopAvailableTable: WorkshopAvailableTableProps = {
    data: dataTableWorkshopAvailable,
    onCheckSchedule,
  };

  const propertiesWorkshopRegisteredTable: WorkshopAvailableTableProps = {
    data: dataTableWorkshopRegistered,
    onCheckSchedule,
  };

  const propertiesWorkshopAttendedTable: WorkshopAttendedTableProps = {
    data: dataTableWorkshopAttended,
  };

  return (
    <div className="tab-workshop">
      <MDBox className="tab-workshop__container">
        <Grid className={"tab-workshop__group-tab"} container alignItems="center" gap={1}>
          {WorkshopTabsData.tabs.map((item, index) => {
            const propertiesGroupTabButton: GroupTabButtonProps = {
              title: item.title,
              isActive: tabWorkshop === item.title,
              onClick: () => {
                onClickTab(item.title);
              },
            };

            return (
              <GroupTabButton
                key={index}
                className="tab-workshop__group-tab__item"
                {...propertiesGroupTabButton}
              />
            );
          })}
        </Grid>

        {isLoadingData ? (
          <Loading style={{ width: 300, height: 200, margin: "10% auto" }} />
        ) : (
          <MDBox className="tab-workshop__content_container">
            {tabWorkshop === WorkshopTabEnum.Available && (
              <WorkshopAvailableTable {...propertiesWorkshopAvailableTable} />
            )}
            {tabWorkshop === WorkshopTabEnum.Registered && (
              <WorkshopRegisteredTable {...propertiesWorkshopRegisteredTable} />
            )}
            {tabWorkshop === WorkshopTabEnum.Attended && (
              <WorkshopAttendedTable {...propertiesWorkshopAttendedTable} />
            )}
          </MDBox>
        )}
      </MDBox>
    </div>
  );
};

export default TabWorkshop;
