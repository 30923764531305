import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "examples/Header";
import MDBox from "components/MDBox";
import { useSelector } from "react-redux";
import { selectAuth } from "shared/redux/slices/authSlice";
import UserCard from "components/UserCard/UserCard";
import Grid from "@mui/material/Grid";
import GroupTabButton from "components/GroupTabButton/GroupTabButton";
import TabCourse from "components/TabCourse/TabCourse";
import TabWorkshop, { TabWorkshopProps } from "components/TabWorkshop/TabWorkshop";
import type { Slot, Tab, WorkshopDataResult } from "./MyDevelopmentContainer.interface";
import {
  MyDevelopmentData,
  MyDevelopmentTabEnum,
  WorkshopTabEnum,
} from "data/my-development/MyDevelopment.data";
import {
  useLazyGetDataWorkshopQuery,
  useLazyGetListPhaseQuery,
  useLazyGetPhaseBoardQuery,
} from "shared/redux/endpoints/my-development";
import { showToastError } from "utilities/toastUtil";
import { Course, Organizer } from "./MyDevelopmentContainer.interface";
import { SVGIcon } from "components/icons";
import { formatDuration, formatTime } from "utilities/times";
import type { WorkshopAvailableTableInterface } from "components/WorkshopAvailableTable/WorkshopAvailableTable.interface";
import type { WorkshopRegisteredTableInterface } from "components/WorkshopRegisteredTable/WorkshopRegisteredTable.interface";
import type { WorkshopAttendedTableInterface } from "components/WorkshopAttendedTable/WorkshopAttendedTable.interface";

const MyDevelopmentContainer = () => {
  const auth = useSelector(selectAuth);
  const {
    user: { email },
  } = auth;

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [getPhaseBoard, phaseBoardResult] = useLazyGetPhaseBoardQuery();
  const [getListPhase, listPhaseResult] = useLazyGetListPhaseQuery();

  const [getDataWorkshop] = useLazyGetDataWorkshopQuery();
  const [
    dataTableWorkshopAvailable: WorkshopAvailableTableInterface[],
    setDataTableWorkshopAvailable,
  ] = useState([]);
  const [
    dataTableWorkshopRegistered: WorkshopRegisteredTableInterface[],
    setDataTableWorkshopRegistered,
  ] = useState([]);
  const [
    dataTableWorkshopAttended: WorkshopAttendedTableInterface[],
    setDataTableWorkshopAttended,
  ] = useState([]);

  const [tabMyDevelopment, setTabMyDevelopment] = useState(MyDevelopmentTabEnum.Course);
  const [tabWorkshop, setTabWorkshop] = useState(WorkshopTabEnum.Available);
  const [open, setOpen] = useState([true, false, false]);
  const [isScrolling, setIsScrolling] = useState(false);

  const _getDescription = (location: string, date_start_time: string, trainer: string) => {
    return (
      <div style={{ display: "inline-flex", alignItems: "center", gap: "4px", flexWrap: "wrap" }}>
        <SVGIcon name={"location"} width={10} height={12} fill={"#2376BC"} />
        <span>{location}</span>

        {date_start_time && (
          <span>
            - <strong>{formatTime(date_start_time)}</strong>
          </span>
        )}

        {trainer && (
          <span>
            by <strong>{trainer}</strong>
          </span>
        )}
      </div>
    );
  };

  const _processData = (result: WorkshopDataResult, type: WorkshopTabEnum) => {
    const output = [];

    result.courses.forEach((course: Course) => {
      course.organizers.forEach((organizer: Organizer) => {
        const workshopData: WorkshopAvailableTableInterface = {
          id: organizer.id,
          courseId: course.course_info.id,
          organizerId: organizer.id,
          workshopName: organizer.name,
          duration: 0,
          description: (
            <div dangerouslySetInnerHTML={{ __html: organizer.description || "" }}></div>
          ),
        };

        if (type === WorkshopTabEnum.Available) {
          // Luôn lấy slot unregistered đầu tiên, những unregistered tiếp theo sẽ bị bỏ qua
          // Nếu có registered thì bỏ qua
          if (organizer.slots.unregistered.length > 0 && organizer.slots.registered.length === 0) {
            output.push({
              ...workshopData,
              duration: formatDuration(organizer.slots.unregistered[0].duration),
            });
          }
        }

        if (type === WorkshopTabEnum.Registered) {
          if (organizer.slots.registered.length > 0) {
            output.push({
              ...workshopData,
              duration: formatDuration(organizer.slots.registered[0].duration),
              rowSub: organizer.slots.registered.map((slot: Slot) => ({
                id: slot.id,
                description: _getDescription(
                  slot.location,
                  slot.start_time,
                  slot.trainers.join(", ")
                ),
              })),
            });
          }
        }

        if (type === WorkshopTabEnum.Attended) {
          organizer.slots.registered.forEach((registered: Slot) => {
            // is registered = true means the workshop has been passed and registered.
            if (registered && registered.registration_status?.has_attended) {
              output.push({
                ...workshopData,
                duration: formatDuration(registered.duration),
                date_time_start: formatTime(registered.start_time),
                location: registered.location,
                trainer: registered.trainers.join(", "),
              });
            }
          });

          organizer.slots.unregistered.forEach((unregistered: Slot) => {
            // is registered = true means the workshop has been passed and registered.
            if (unregistered && unregistered.registration_status?.has_attended) {
              output.push({
                ...workshopData,
                duration: formatDuration(unregistered.duration),
                date_time_start: formatTime(unregistered.start_time),
                location: unregistered.location,
                trainer: unregistered.trainers.join(", "),
              });
            }
          });

          organizer.slots.old.forEach((old: Slot) => {
            // is registered = true means the workshop has been passed and registered.
            if (old && old.registration_status?.has_attended) {
              output.push({
                ...workshopData,
                duration: formatDuration(old.duration),
                date_time_start: formatTime(old.start_time),
                location: old.location,
                trainer: old.trainers.join(", "),
              });
            }
          });
        }
      });
    });

    return output;
  };

  const propertiesTabWorkshop: TabWorkshopProps = {
    tabWorkshop,
    isLoadingData,
    dataTableWorkshopAvailable: dataTableWorkshopAvailable,
    dataTableWorkshopRegistered: dataTableWorkshopRegistered,
    dataTableWorkshopAttended: dataTableWorkshopAttended,
    onClickTab: async (tab: WorkshopTabEnum, isForceFetchData: boolean) => {
      if (tab !== tabWorkshop || isForceFetchData) {
        setTabWorkshop(tab);
        setIsLoadingData(true);
        const result = await getDataWorkshop(email); // Call API
        setIsLoadingData(false);
        if (!result?.data) return;
        if (result?.data && result?.data.error) {
          // Error API
          const message = result?.data.error.data.message;
          showToastError(message);
          return;
        }
        console.log(result);
        const dataResponse = result?.data;
        if (!dataResponse) return;

        if (tab === WorkshopTabEnum.Available) {
          setDataTableWorkshopAvailable(_processData(dataResponse, tab));
          return;
        }

        if (tab === WorkshopTabEnum.Registered) {
          setDataTableWorkshopRegistered(_processData(dataResponse, tab));
          return;
        }

        if (tab === WorkshopTabEnum.Attended) {
          setDataTableWorkshopAttended(_processData(dataResponse, tab));
        }
      }
    },
    onCheckSchedule: (courseId: number, organizerId: number) => {
      let url = `https://welearn.apollo.vn/dl-api/workshop?email=${email}&course=${courseId}&instance=${organizerId}`;
      window.open(url, "_blank");
    },
  };

  const onChangeTabMyDevelopment = (tag: Tab) => {
    setTabMyDevelopment(tag.title);

    if (tag.title === MyDevelopmentTabEnum.Course) {
      getPhaseBoard();
      getListPhase();
      return;
    }

    if (tag.title === MyDevelopmentTabEnum.Workshop) {
      propertiesTabWorkshop.onClickTab(tabWorkshop, true);
      return;
    }

    if (tag.title === MyDevelopmentTabEnum.Observation) {
    }
  };

  useEffect(() => {
    getPhaseBoard();
    getListPhase();

    propertiesTabWorkshop.onClickTab(tabWorkshop, true);
  }, []);

  return (
    <DashboardLayout>
      <Header />
      <MDBox className="my-development-container">
        <div className="my-development-container__card_header">
          <UserCard user={auth?.user} role={auth?.role} />
          <Grid className="my-development-container__card_header__tabs">
            {MyDevelopmentData.tabs.map((tag, index) => {
              return (
                <GroupTabButton
                  key={index}
                  title={tag.title}
                  isActive={tabMyDevelopment === tag.title}
                  onClick={() => onChangeTabMyDevelopment(tag)}
                />
              );
            })}
          </Grid>
        </div>
        {tabMyDevelopment === MyDevelopmentTabEnum.Course && (
          <TabCourse
            open={open}
            setOpen={setOpen}
            dataList={listPhaseResult}
            dataBoard={phaseBoardResult}
            isScrolling={isScrolling}
            setIsScrolling={setIsScrolling}
          />
        )}
        {tabMyDevelopment === MyDevelopmentTabEnum.Workshop && (
          <TabWorkshop {...propertiesTabWorkshop} />
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default MyDevelopmentContainer;
