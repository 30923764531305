import { PieChart } from "@mui/x-charts";
import { styled } from "@mui/material";
import MDBox from "components/MDBox";
import { useMemo } from "react";

const StyledTopLabel = styled("text")(({ theme }) => ({
  fill: "#344767",
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 1.37,
  fontFamily: theme.typography.lexendFont,
}));

const StyledBottomLabel = styled("text")(({ theme }) => ({
  fill: "#7B809A",
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 12,
  fontWeight: 700,
  lineHeight: 1.5,
  fontFamily: theme.typography.lexendFont,
}));

// eslint-disable-next-line
function TopLabel({ children }) {
  return (
    <StyledTopLabel x={40} y={30}>
      {children}
    </StyledTopLabel>
  );
}

// eslint-disable-next-line
function BottomLabel({ children }) {
  return (
    <StyledBottomLabel x={40} y={47.5}>
      {children}
    </StyledBottomLabel>
  );
}

// eslint-disable-next-line
export const CoursePieChart = ({ data }) => {
  const label = useMemo(() => {
    const notComplete = (data ?? []).find((item) => item.label === "complete").value ?? 0;
    const total = (data ?? []).reduce((sum, item) => {
      const value = Number(item?.value || 0);
      return sum + value;
    }, 0);

    return `${notComplete}/${total}`;
  }, [data]);
  return (
    <MDBox display="flex" justifyContent="flex-start">
      <PieChart
        series={[
          {
            data,
            innerRadius: 35,
            outerRadius: 40,
            paddingAngle: 1,
            cx: 35,
            cy: 35,
          },
        ]}
        slotProps={{
          legend: { hidden: true },
          popper: {
            sx: {
              "*": {
                fontFamily: "Lexend",
                fontSize: 12,
                color: "black",
                fontWeight: "bold",
              },
            },
          },
        }}
        height={80}
        width={80}
      >
        {label ? (
          <>
            <TopLabel>{label}</TopLabel>
            <BottomLabel>completed</BottomLabel>
          </>
        ) : null}
      </PieChart>
    </MDBox>
  );
};
