// Default toast options
import { toast } from "react-toastify";

const defaultOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  style: {
    fontFamily: "Lexend",
    fontSize: 14,
  },
};

export const showToastError = (message, options = {}) => {
  toast.error(message, { ...defaultOptions, ...options });
};

export const showToastSuccess = (message, options = {}) => {
  toast.success(message, { ...defaultOptions, ...options });
};

export const showToastInfo = (message, options = {}) => {
  toast.info(message, { ...defaultOptions, ...options });
};

export const showToastWarning = (message, options = {}) => {
  toast.warn(message, { ...defaultOptions, ...options });
};
