import PropTypes from "prop-types";

export const IconLocation = ({ width, height, fill }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 10 12"
        fill={fill}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.72888 0.167969C2.82409 0.167969 1.27539 1.73048 1.27539 3.65778C1.27539 4.31062 1.56293 5.12053 1.98144 5.92839C2.90288 7.70732 4.43776 9.49904 4.43776 9.49904C4.51041 9.58397 4.61683 9.63308 4.72888 9.63308C4.84093 9.63308 4.94734 9.58397 5.02 9.49904C5.02 9.49904 6.55488 7.70732 7.47632 5.92839C7.89483 5.12053 8.18237 4.31062 8.18237 3.65778C8.18237 1.73048 6.63367 0.167969 4.72888 0.167969ZM4.72888 2.21448C3.95223 2.21448 3.3219 2.84481 3.3219 3.62146C3.3219 4.39811 3.95223 5.02843 4.72888 5.02843C5.50553 5.02843 6.13586 4.39811 6.13586 3.62146C6.13586 2.84481 5.50553 2.21448 4.72888 2.21448Z"
          fill="#8392AB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.17633 8.41946C7.59228 8.5443 7.93507 8.69881 8.17195 8.87993C8.32544 8.99658 8.438 9.11067 8.438 9.24983C8.438 9.33169 8.39144 9.40588 8.32595 9.48007C8.21749 9.60235 8.05633 9.71235 7.85679 9.81467C7.15177 10.1754 6.01186 10.401 4.7287 10.401C3.44553 10.401 2.30563 10.1754 1.6006 9.81467C1.40107 9.71235 1.23991 9.60235 1.13144 9.48007C1.06595 9.40588 1.01939 9.33169 1.01939 9.24983C1.01939 9.11067 1.13195 8.99658 1.28544 8.87993C1.52233 8.69881 1.86512 8.5443 2.28107 8.41946C2.48367 8.35858 2.59879 8.14421 2.53791 7.9416C2.47702 7.73849 2.26265 7.62337 2.06005 7.68425C1.42665 7.87509 0.934465 8.13653 0.638744 8.4256C0.378837 8.67886 0.251953 8.9623 0.251953 9.24983C0.251953 9.609 0.455069 9.96714 0.867953 10.2659C1.59242 10.7898 3.04749 11.1684 4.7287 11.1684C6.40991 11.1684 7.86498 10.7898 8.58944 10.2659C9.00233 9.96714 9.20544 9.609 9.20544 9.24983C9.20544 8.9623 9.07856 8.67886 8.81865 8.4256C8.52293 8.13653 8.03074 7.87509 7.39735 7.68425C7.19474 7.62337 6.98037 7.73849 6.91949 7.9416C6.8586 8.14421 6.97372 8.35858 7.17633 8.41946Z"
          fill="#8392AB"
        />
      </svg>
    </>
  );
};

IconLocation.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
