import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "examples/Header";
import WeeklyCalendar from "./components/WeeklCalendar";
import MonthCalendar from "./components/MonthCalendar";
import calendarStyles from "./style";
import { useEffect, useState } from "react";
import moment from "moment";
import InfoIcon, { TooltipIconColor, TooltipType } from "components/InfoIcon";
import { teacherInfoIconBlock } from "components/InfoIcon/teacherInfoIcon";
import { useSelector } from "react-redux";
import { selectAuth } from "shared/redux/slices/authSlice";
import { Helpers } from "shared/utils/common";
import { useLazyGetDataWorkshopQuery } from "shared/redux/endpoints/my-development";

function Calendar() {
  const [start, setStart] = useState(moment().startOf("isoWeek").weekday(1).format("YYYY-MM-DD"));
  const [end, setEnd] = useState(moment().startOf("isoWeek").weekday(7).format("YYYY-MM-DD"));
  const [convertDataWorkShop, setConvertDataWorkShop] = useState([]);
  const [getDataWorkshop] = useLazyGetDataWorkshopQuery();
  const auth = useSelector(selectAuth);
  const { getRegisteredSlots } = Helpers;

  const {
    user: { email },
  } = auth;

  useEffect(() => {
    getDataWorkshop(email).then((data) => {
      if (data.isSuccess) {
        const convertDataWorkShopResult = getRegisteredSlots(data.data, email);
        setConvertDataWorkShop(convertDataWorkShopResult);
      }
    });

    // getDataWorkshop("demo").then((data) => {
    //   if (data.isSuccess) {
    //     const convertDataWorkShopResult = getRegisteredSlots(data.data, "demo");
    //     setConvertDataWorkShop(convertDataWorkShopResult);
    //   }
    // });
  }, []);

  return (
    <DashboardLayout>
      <Header />
      <MDBox sx={calendarStyles}>
        <MDBox
          sx={() => ({
            width: "100%",
          })}
        >
          <InfoIcon
            type={TooltipType.TYPE_2}
            teacherInfoIconBlock={teacherInfoIconBlock.WEEK_MONTH}
            color={TooltipIconColor.BLUE}
          >
            <Grid container columnSpacing={2} rowGap={2}>
              <Grid item xs={12} xl>
                <WeeklyCalendar outerDate={start} convertDataWorkShop={convertDataWorkShop} />
              </Grid>
              <Grid item xs={12} xl="auto" mb={{ xs: 3, xl: 0 }}>
                <MonthCalendar
                  start={start}
                  setStart={setStart}
                  end={end}
                  setEnd={setEnd}
                  convertDataWorkShop={convertDataWorkShop}
                />
              </Grid>
            </Grid>
          </InfoIcon>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Calendar;
