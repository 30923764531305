import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { descriptionTypography, titleTypography } from "shared/styles/style";

const UserCard = ({ user, role }) => {
  if (!user) return null;

  return (
    <MDBox display="flex" alignItems="center" gap={3}>
      <MDBox component="img" src={user.avatar} sx={{ width: "74px", height: "74px" }} />
      <MDBox>
        <MDTypography
          sx={({ breakpoints }) => ({
            ...titleTypography(breakpoints),
            lineHeight: 1.37,
            letterSpacing: "-0.8px",
            marginBottom: "4px",
          })}
        >
          {user.fullName}
        </MDTypography>
        <MDTypography
          sx={({ breakpoints }) => ({
            ...descriptionTypography(breakpoints),
            lineHeight: 1.5,
          })}
        >
          {role}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default UserCard;
