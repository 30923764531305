/**
 * Validate email
 */
import queryString from "query-string";

const validateEmail = (email) => {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return reg.test(email);
};

/**
 * Validate specific phone
 */
const validatePhone = (phone) => {
  let reg = /^(([+]{0,1}\d{2})|\d?)[\s-]?[0-9]{2}[\s-]?[0-9]{3}[\s-]?[0-9]{4}$/;
  return reg.test(phone);
};

/**
 * Generate user name
 */
export const generateTwoNumber = (min, max) => {
  return Math.floor(min + Math.random() * (max + 1 - min));
};

export const removeAccents = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[đĐ]/g, "d")
    .replace(/-/g, "")
    .replace(/([^0-9a-z-A-Z\s])/g, "");
};

export const containSpecialChar = (str) => {
  return str.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) ? true : false;
};

/**
 * Generate class code
 */
const generateClassCode = (length) => {
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const uppercase = lowercase.toUpperCase();
  const numbers = "0123456789";
  const [lower, upper, num] = [
    Math.ceil(length / 3),
    Math.ceil(length / 3),
    length - 2 * Math.ceil(length / 3),
  ];
  const shuffle = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };
  const getCharacters = (length, string) => {
    let characters = [];
    for (let i = 0; i < length; i++) {
      let random = Math.floor(Math.random() * string.length);
      characters[i] = string.charAt(random);
    }
    return characters;
  };
  const passwordCharacters = shuffle([
    ...getCharacters(lower, lowercase),
    ...getCharacters(upper, uppercase),
    ...getCharacters(num, numbers),
  ]);
  return passwordCharacters.join("");
};

const getSourceTypeFromUrl = (url) => {
  return validateYouTubeUrl(url) ? "youtube" : "s3";
};

function validateYouTubeUrl(url) {
  return url.includes("youtube.com") || url.includes("youtu.be") ? true : false;
}

function checkVideoAvailability(videoUrl) {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement("video");
    videoElement.src = videoUrl;

    videoElement.addEventListener("loadedmetadata", () => {
      resolve(videoElement.duration);
    });

    videoElement.addEventListener("error", (e) => {
      resolve(false);
    });
  });
}

const isShortLinkYoutube = (url) => {
  return url.includes("youtube") && url.includes("short") ? true : false;
};
const parseObjectToString = (search) => {
  return queryString.stringify(search);
};

const createHTML = (html) => {
  return html ? <div dangerouslySetInnerHTML={{ __html: html.replace(/\n/g, "<br>") }} /> : "";
};

export const objectToQueryString = (params) => {
  const queryParts = [];

  for (const [key, value] of Object.entries(params)) {
    queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  }

  return queryParts.join("&");
};

const joinArrayClassname = (arrayClassname = []) => {
  return arrayClassname.join(" ");
};

export const canParseStringToObject = (inputString = "") => {
  if (typeof inputString !== "string") return false;
  try {
    const result = JSON.parse(inputString);
    return result;
  } catch (error) {
    console.error("Failed to parse JSON:", error);
    // Xử lý thêm hoặc trả về giá trị mặc định tùy theo nghiệp vụ
    return null; // Ví dụ, trả về null hoặc {}
  }
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const getRegisteredSlots = (data, email) => {
  const courses = data.courses || [];
  const registeredSlots = [];
  courses.forEach((course) => {
    const organizers = course.organizers || [];

    organizers.forEach((organizer) => {
      const slots = organizer.slots || {};
      const registered = slots.registered || [];
      const name = organizer.name || "";
      const courseId = course.course_info.id || "";
      const organizerId = organizer.id || "";

      registered.forEach((slot) => {
        registeredSlots.push({
          ...slot,
          id: slot.id,
          startTime: new Date(slot.start_time).getTime(),
          endTime: new Date(slot.start_time).getTime() + slot.duration * 1000,
          duration: slot.duration * 1000,
          eventTypeName: name,
          centerName: slot.location,
          trainers: slot.trainers,
          courseId,
          organizerId,
          email,
          deadline: new Date(slot.start_time).getTime() + slot.duration * 1000,
        });
      });
    });
  });

  return registeredSlots;
};

export const Helpers = {
  validateEmail,
  validatePhone,
  generateTwoNumber,
  removeAccents,
  containSpecialChar,
  generateClassCode,
  getSourceTypeFromUrl,
  validateYouTubeUrl,
  checkVideoAvailability,
  parseObjectToString,
  isShortLinkYoutube,
  createHTML,
  objectToQueryString,
  joinArrayClassname,
  canParseStringToObject,
  sleep,
  getRegisteredSlots,
};
