import FullCalendar from "@fullcalendar/react";
import { Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MonthNextIcon from "components/icons/MonthNextIcon";
import MonthPrevIcon from "components/icons/MonthPrevIcon";
import moment from "moment";
import { useRef, useState } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "./custom.css";
import Position from "../Position";
import interactionPlugin from "@fullcalendar/interaction";
import { titleTypography } from "shared/styles/style";
import InfoIcon, { TooltipIconColor, TooltipType } from "components/InfoIcon";
import { teacherInfoIconBlock } from "components/InfoIcon/teacherInfoIcon";
import makeStyles from "pages/style";

// eslint-disable-next-line
function MonthCalendar({ start, setStart, end, setEnd, convertDataWorkShop }) {
  const classes = makeStyles();
  const calendarRef = useRef(null);

  const [title, setTitle] = useState("");

  // const [start, setStart] = useState(moment().startOf("isoWeek").weekday(1).format("YYYY-MM-DD"));
  // const [end, setEnd] = useState(moment().startOf("isoWeek").weekday(7).format("YYYY-MM-DD"));

  return (
    <MDBox
      sx={({ breakpoints }) => ({
        boxShadow: "0px 2px 6px 0px #00000040",
        height: "100%",
        width: 340,
        maxWidth: "100%",
        borderRadius: "20px",
        gap: "16px",
        padding: "24px",
        background: "#fff",

        [breakpoints.down(1600)]: {
          width: 272,
          padding: "16px",
        },

        [breakpoints.down("xl")]: {
          width: "100%",
        },
      })}
      id="right_side_calendar"
    >
      <Grid container columnSpacing={2} justifyContent="center">
        <Grid item xs={12} md="auto" xxl={12} display="flex" justifyContent="center">
          <MDBox
            sx={({ breakpoints }) => ({
              borderBottom: "1px solid #dee2e6",
              borderLeft: 0,
              paddingBottom: "16px",
              marginBottom: "16px",
              width: "100%",
              maxWidth: 292,

              [breakpoints.down(1600)]: {
                width: 224,
                borderBottom: 0,
              },
            })}
          >
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <InfoIcon
                type={TooltipType.TYPE_1}
                teacherInfoIconBlock={teacherInfoIconBlock.SMALL_CALENDAR}
                color={TooltipIconColor.BLUE}
                className={classes.containerInfoIconWith6}
              >
                <MDTypography
                  sx={({ breakpoints }) => ({
                    ...titleTypography(breakpoints),
                    lineHeight: 1.5,
                    letterSpacing: "0em",
                    textAlign: "left",
                  })}
                >
                  {title}
                </MDTypography>
              </InfoIcon>

              <MDBox sx={{ display: "flex", gap: "8px" }}>
                <MDBox
                  onClick={() => {
                    calendarRef.current?.getApi().prev();
                    setTitle(calendarRef.current?.getApi().view.title || "");
                  }}
                >
                  <Icon component={MonthPrevIcon} />
                </MDBox>
                <MDBox
                  onClick={() => {
                    calendarRef.current?.getApi().next();
                    setTitle(calendarRef.current?.getApi().view.title || "");
                  }}
                >
                  <Icon component={MonthNextIcon} />
                </MDBox>
              </MDBox>
            </MDBox>

            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, interactionPlugin]}
              titleFormat={(info) => {
                const startDate = info.start.marker;
                return `${moment(startDate).format("MMM")}, ${moment(startDate).format("YYYY")}`;
              }}
              viewDidMount={({ view }) => {
                setTitle(view.title);
              }}
              initialView="dayGridMonth"
              firstDay={1}
              headerToolbar={false}
              height="auto"
              selectable={true}
              select={(info) => {
                const { start, end } = info;
                const isSame =
                  moment(start).format("YYYY-MM-DD") ===
                  moment(end).add(-1, "day").format("YYYY-MM-DD");
                if (!isSame) {
                  calendarRef?.current?.getApi?.().unselect?.();
                } else {
                  setStart(moment(start).format("YYYY-MM-DD"));
                  setEnd(moment(end).add(-1, "day").format("YYYY-MM-DD"));
                }
              }}
              events={[
                {
                  start,
                  end: moment(end).add(1, "day").format("YYYY-MM-DD"),
                  display: "background",
                },
              ]}
              dayHeaderContent={({ date }) => {
                return (
                  <MDBox sx={{ display: "flex", flexDirection: "column" }}>
                    {moment(date).format("dd")}
                  </MDBox>
                );
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md xxl={12}>
          <Position start={start} end={end} convertDataWorkShop={convertDataWorkShop} />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default MonthCalendar;
