import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import CompletionRateCard from "../CompletionRateCard";
import PhaseCard from "../PhaseCard";
import PhaseBox from "../PhaseBox";
import { teacherInfoIconBlock } from "../InfoIcon/teacherInfoIcon";
import { firstRowStyle } from "pages/development/style";
import makeStyles from "pages/style";

const TabCourse = ({ open, setOpen, isScrolling, setIsScrolling, dataBoard, dataList }) => {
  const classes = makeStyles();
  const phaseMapping = {
    1: teacherInfoIconBlock.PHASE_1,
    2: teacherInfoIconBlock.PHASE_2,
    3: teacherInfoIconBlock.PHASE_3,
  };

  const progressPhase1 = dataBoard?.data?.phaseComplete?.phase_1;
  const progressPhase2 = dataBoard?.data?.phaseComplete?.phase_2;
  const progressPhase3 = dataBoard?.data?.phaseComplete?.phase_3;
  const completeRate = dataBoard?.data?.completeRate;
  const statusPhase1 = dataList?.data?.openPhase1;
  const statusPhase2 = dataList?.data?.openPhase2;
  const statusPhase3 = dataList?.data?.openPhase3;
  const phaseData = [
    {
      title: "WELEARN - PHASE 1",
      //desc: "0-6 months",
      // desc: "You are required to finish all the courses below",
      data: dataList?.data?.phase?.phase_1,
      disable: !statusPhase1,
    },
    {
      title: "WELEARN - PHASE 2",
      //desc: "7-11.5 months",
      // desc: "Copy the code bellow to your registered provider.",
      data: dataList?.data?.phase?.phase_2,
      disable: !statusPhase2,
    },
    {
      title: "WELEARN - PHASE 3",
      // desc: "2nd year +",
      // desc: "Copy the code bellow to your registered provider.",
      data: dataList?.data?.phase?.phase_3,
      disable: !statusPhase3,
    },
  ];
  const dataPhase1 = [
    {
      id: 0,
      value: progressPhase1?.complete,
      label: "complete",
      color: "#5E72E4",
    },
    {
      id: 1,
      value: progressPhase1?.notComplete,
      label: "not complete",
      color: "#F5365C",
    },
  ];

  const dataPhase2 = [
    {
      id: 0,
      value: progressPhase2?.complete,
      label: "complete",
      color: "#5E72E4",
    },
    {
      id: 1,
      value: progressPhase2?.notComplete,
      label: "not complete",
      color: "#F5365C",
    },
  ];

  const dataPhase3 = [
    {
      id: 0,
      value: progressPhase3?.complete,
      label: "complete",
      color: "#5E72E4",
    },
    {
      id: 1,
      value: progressPhase3?.notComplete,
      label: "not complete",
      color: "#F5365C",
    },
  ];

  useEffect(() => {
    const events = document.querySelectorAll(`[data-horizontal-scroll]`);

    let isDown = false;
    let startX = 0;
    let scrollLeft = 0;
    let scrollTimeout;
    events.forEach((event) => {
      event.addEventListener("mousedown", (e) => {
        isDown = true;
        event.classList.add("active");
        startX = e.pageX - event.offsetLeft;
        scrollLeft = event.scrollLeft;
      });

      event.addEventListener("mouseleave", () => {
        isDown = false;
        event.classList.remove("active");
      });
      event.addEventListener("mouseup", () => {
        isDown = false;
        event.classList.remove("active");
      });
      event.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - event.offsetLeft;

        const walk = x - startX; // scroll-fast

        event.scrollLeft = scrollLeft - walk;
      });

      const handleScroll = () => {
        setIsScrolling(true);

        clearTimeout(scrollTimeout);

        scrollTimeout = setTimeout(() => {
          setIsScrolling(false);
        }, 500);
      };

      event?.addEventListener("scroll", handleScroll);

      return () => {
        event?.removeEventListener("scroll", handleScroll);
      };
    });
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        columnSpacing={{ xs: 1, md: 2, xxxl: 3 }}
        sx={(theme) => firstRowStyle(theme)}
      >
        <CompletionRateCard completeRate={completeRate} classes={classes} />

        {[1, 2, 3].map((item, index) => (
          <PhaseCard
            key={item}
            item={item}
            index={index}
            open={open}
            setOpen={setOpen}
            phaseMapping={phaseMapping}
            dataCoursePieChart={item === 1 ? dataPhase1 : item === 2 ? dataPhase2 : dataPhase3}
            classes={classes}
          />
        ))}
      </Grid>

      {phaseData.map((item, index) => (
        <PhaseBox
          key={item.title}
          item={item}
          index={index}
          open={open}
          isScrolling={isScrolling}
        />
      ))}
    </>
  );
};

export default TabCourse;
