import React from "react";
import { Grid } from "@mui/material";
import { itemStyle, itemTitleStyle } from "pages/development/style";
import MDBox from "./MDBox";
import InfoIcon, { TooltipIconColor, TooltipType } from "./InfoIcon";
import MDTypography from "./MDTypography";
import { CoursePieChart } from "pages/development/components/PieChart";
import { titleTypography } from "shared/styles/style";

const PhaseCard = ({ item, index, open, setOpen, phaseMapping, dataCoursePieChart, classes }) => {
  const handleClick = () => {
    setOpen((_open) => {
      let temp = [false, false, false];
      temp[index] = !_open[index];
      return temp;
    });
  };

  return (
    <Grid item xs={12} md={6} xxl={2.66} key={item}>
      <MDBox
        sx={(theme) => itemStyle(theme, { type: "course-control", active: open[index] })}
        onClick={handleClick}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <MDBox display="flex" justifyContent="flex-start" alignItems="center" gap={1}>
            <InfoIcon
              type={TooltipType.TYPE_1}
              teacherInfoIconBlock={phaseMapping[item]}
              color={TooltipIconColor.BLUE}
              className={classes.containerInfoIconWithHeading}
            >
              <MDTypography
                sx={(theme) => ({
                  ...titleTypography(theme.breakpoints),
                  ...itemTitleStyle(theme),
                })}
              >
                Phase {item}
              </MDTypography>
            </InfoIcon>
          </MDBox>
        </MDBox>

        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            display="flex"
            justifyContent="flex-start"
            mr={2}
            sx={{ flexBasis: 80, flexGrow: 0 }}
          >
            {dataCoursePieChart && <CoursePieChart data={dataCoursePieChart} />}
          </MDBox>
          <MDBox sx={{ flexGrow: 1 }}>
            <Grid container alignItems="center" p={{ xs: 0.25, xxl: 0.5 }}>
              <Grid xs="auto" item>
                <MDBox
                  sx={({ breakpoints, functions: { pxToRem } }) => ({
                    width: pxToRem(24),
                    height: pxToRem(24),
                    borderRadius: pxToRem(8),
                    background: "#5E72E4",
                    [breakpoints.down(1600)]: {
                      width: pxToRem(20),
                      height: pxToRem(20),
                      borderRadius: pxToRem(4),
                    },
                  })}
                />
              </Grid>
              <Grid
                xs
                item
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                ml={2}
              >
                <MDTypography
                  sx={({ breakpoints }) => ({
                    ...titleTypography(breakpoints),
                    textTransform: "unset",
                    lineHeight: 1.625,
                    letterSpacing: "-0.8px",
                  })}
                >
                  Complete
                </MDTypography>
              </Grid>
            </Grid>

            <Grid container sx={{ width: "100%" }} alignItems="center" p={{ xs: 0.25, xxl: 0.5 }}>
              <Grid xs="auto" item>
                <MDBox
                  sx={({ breakpoints, functions: { pxToRem } }) => ({
                    width: pxToRem(24),
                    height: pxToRem(24),
                    borderRadius: pxToRem(8),
                    background: "#F5365C",
                    [breakpoints.down(1600)]: {
                      width: pxToRem(20),
                      height: pxToRem(20),
                      borderRadius: pxToRem(4),
                    },
                  })}
                />
              </Grid>
              <Grid
                xs
                item
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                ml={2}
              >
                <MDTypography
                  sx={({ breakpoints }) => ({
                    ...titleTypography(breakpoints),
                    textTransform: "unset",
                    lineHeight: 1.625,
                    letterSpacing: "-0.8px",
                  })}
                >
                  Not Complete
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </MDBox>
    </Grid>
  );
};

export default PhaseCard;
