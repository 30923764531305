import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./shared/styles/index.css";
import App from "App";
import { MaterialUIControllerProvider } from "context";

// Redux Provider
import { Providers } from "shared/redux/provider";

// Toasts
import { ToastContainer } from "react-toastify";

// CSS Libraries
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import { ReactIdleTimerProviders } from "shared/libs/ReactIdleTimer";
import LoadingFullPage from "components/LoadingFullPage";
import "assets/style/_all.scss";

const container = document.getElementById("app");
const root = createRoot(container);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registration successful, scope is:", registration.scope);
    })
    .catch((err) => {
      console.error("Service Worker registration failed:", err);
    });
}

root.render(
  <Providers>
    <ReactIdleTimerProviders>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <App />
          <ToastContainer
            style={{
              "& *": {
                fontFamily: "Lexend",
              },
            }}
          />
          <LoadingFullPage />
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </ReactIdleTimerProviders>
  </Providers>
);
