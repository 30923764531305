import React from "react";

export interface GroupTabButtonProps {
  title: string;
  isActive: boolean;
  onClick: () => void;
}

const GroupTabButton = ({ title, isActive, onClick }) => {
  return (
    <div className="group-tab-button">
      <div className={`group-tab-button__item ${isActive ? "active" : ""}`} onClick={onClick}>
        <div className="group-tab-button__title">{title}</div>
      </div>
    </div>
  );
};

export default GroupTabButton;
