import Grid from "@mui/material/Grid";
import MDBox from "./MDBox";
import {
  descriptionStyle,
  iconStyle,
  itemStyle,
  itemTitleStyle,
  percentStyle,
} from "../pages/development/style";
import InfoIcon, { TooltipIconColor, TooltipType } from "./InfoIcon";
import { teacherInfoIconBlock } from "./InfoIcon/teacherInfoIcon";
import MDTypography from "./MDTypography";
import { descriptionTypography, titleTypography } from "shared/styles/style";
import CompletionRateIcon from "assets/images/apollo-english/complete-rate-icon.png";
import { SVGIcon } from "./icons";

const CompletionRateCard = ({ completeRate, classes }) => {
  return (
    <Grid item xs={12} md={6} xxl={4}>
      <MDBox sx={(theme) => itemStyle(theme, { type: "completion-rate" })}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap={1}
            width="100%"
          >
            <MDBox component="img" src={CompletionRateIcon} sx={(theme) => iconStyle(theme)} />
            <InfoIcon
              type={TooltipType.TYPE_1}
              teacherInfoIconBlock={teacherInfoIconBlock.COMPLETION_RATE}
              color={TooltipIconColor.BLUE}
              className={classes.containerInfoIconWithHeading}
            >
              <MDTypography
                sx={(theme) => ({
                  ...titleTypography(theme.breakpoints),
                  ...itemTitleStyle(theme),
                })}
              >
                COMPLETION RATE
              </MDTypography>
            </InfoIcon>
            <MDBox sx={{ marginLeft: "auto" }}>
              <SVGIcon name="sunny" />
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="flex-start" gap={1} mb={1}>
          <MDTypography variant="span" sx={(theme) => percentStyle(theme)}>
            {typeof completeRate === "number" ? `${completeRate}%` : ""}
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDTypography
            sx={(theme) => ({
              ...descriptionTypography(theme.breakpoints),
              ...descriptionStyle(theme),
            })}
          >
            {
              '"WeLearn" is our in-house training platform designed to equip all our teachers and staff with the essential courses for a smooth onboarding process, as well as the necessary skills for the job.'
            }
          </MDTypography>
        </MDBox>
      </MDBox>
    </Grid>
  );
};

export default CompletionRateCard;
