import React from "react";
import { useTable, useSortBy } from "react-table";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MDInput from "../MDInput";
import MDBox from "../MDBox";
import { WorkshopAvailableTableInterface } from "./WorkshopAvailableTable.interface";

export interface WorkshopAvailableTableProps {
  data: WorkshopAvailableTableInterface[];
  onCheckSchedule: (courseId: number, organizerId: number) => void;
}

const WorkshopAvailableTable = ({ data, onCheckSchedule }: WorkshopAvailableTableProps) => {
  console.log("WorkshopAvailableTable");

  const [searchText, setSearchText] = React.useState("");

  const columnClassMap = {
    workshopName: "workshop-table__row__workshop_name",
    duration: "workshop-table__row__duration",
    description: "workshop-table__row__description",
    action: "workshop-table__row__action",
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Workshop Name",
        accessor: "workshopName",
        align: "left",
        width: "20%",
        isSort: true,
      },
      {
        Header: "Duration",
        accessor: "duration",
        align: "left",
        width: "20%",
        isSort: false,
      },
      {
        Header: "Description",
        accessor: "description",
        align: "left",
        width: "47%",
        isSort: false,
      },
      {
        Header: "Action",
        accessor: "action",
        align: "left",
        width: "18%",
        Cell: ({ row }) => (
          <a
            className="workshop-table__row__action__button"
            onClick={() => onCheckSchedule(row.original.courseId, row.original.organizerId)}
          >
            Check Schedule
          </a>
        ),
      },
    ],
    []
  );

  const filteredData = React.useMemo(() => {
    if (!searchText) return data;
    return data.filter((row) => row.workshopName.toLowerCase().includes(searchText.toLowerCase()));
  }, [data, searchText]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: filteredData,
    },
    useSortBy
  );

  return (
    <MDBox className="workshop-table-avaliable-container">
      <MDBox className="data-table-controls">
        <MDBox className="data-table-controls__container">
          <MDBox className="data-table-controls__page-size"></MDBox>
          <MDBox>
            <MDInput placeholder="Search here..." onChange={(e) => setSearchText(e.target.value)} />
          </MDBox>
        </MDBox>
        <TableContainer>
          <Table {...getTableProps()} className="workshop-table">
            <TableHead className="workshop-table__header_group">
              {headerGroups.map((headerGroup, index) => (
                <TableRow
                  key={index}
                  className="workshop-table__header"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, index) => (
                    <TableCell
                      key={index}
                      className="workshop-table__header-cell"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ width: column.width, textAlign: column.align }}
                    >
                      {column.render("Header")}
                      {column.isSort ? (
                        <span>
                          {sortBy.find((sort) => sort.id === column.id)?.desc ? " ▼" : " ▲"}
                        </span>
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <React.Fragment key={row.id}>
                    {/* Main row */}
                    <TableRow className="workshop-table__row" {...row.getRowProps()}>
                      {row.cells.map((cell, index) => {
                        const columnId = cell.column.id;
                        const cellClass = columnClassMap[columnId] || "workshop-table__default";
                        return (
                          <TableCell
                            key={index}
                            className={`workshop-table__cell ${cellClass}`}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </MDBox>
    </MDBox>
  );
};

export default WorkshopAvailableTable;
